import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-textarea',
  templateUrl: './pc-input-textarea.component.html',
  styleUrls: ['./pc-input-textarea.component.scss'],
})
export class PCInputTextareaComponent extends PcInputBaseComponent {
  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  // Required
  @Input() control: UntypedFormControl

  // Required unless it will look crap
  @Input({ required: true }) placeholder: string

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() variant: 'fit-width'

  // TODO: Implement this in the template
  @Input() errorHint: string

  // TODO: Implement this in the template
  @Input() hint: string

  @Input() inputID: string

  @Input() standAlone: boolean = false

  @Input() resetIconRequired: boolean = true

  inputFieldChanged(event: Event) {
    const value = (event.target as unknown as HTMLInputElement).value
    this.valueChanged.emit(value)
  }

  resetInput() {
    this.control.setValue('')
  }
}
