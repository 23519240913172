import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core'

@Component({
  selector: 'n-times-row',
  templateUrl: './n-times-row.component.html',
  styleUrl: './n-times-row.component.scss',
})
export class NTimesRowComponent implements OnInit {
  @Input()
  n: number

  public dummyArray: number[] = []

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>

  ngOnInit(): void {
    for (let i = 0; i < this.n; i++) {
      this.dummyArray.push(i)
    }
  }
}
